<template>
  <div class="activity" v-if="canAdd">
    <page-header :title="youthHomeLabel" :icon="youthHomeIcon" v-if="isYouthHome">
    </page-header>
    <page-header :title="activitiesTitle" :icon="activitiesIcon" v-else>
    </page-header>
    <loading-gif :loading-name="loadingName"></loading-gif>
    <activity-editor
      :activity="activity"
      :category="category"
      :youth-home="youthHome"
      @done="onActivityCreated($event)"
      @cancel="onCancel"
    ></activity-editor>
  </div>
</template>

<script>
// @ is an alias to /src
import ActivityEditor from '@/components/Activities/ActivityEditor'
import LoadingGif from '@/components/Controls/LoadingGif'
import PageHeader from '@/components/Layout/PageHeader'
import { BackendMixin } from '@/mixins/backend'
import { ActivitiesMixin } from '@/mixins/activities'
import router from '@/router'
import { makeActivity } from '@/types/activities'
import store from '@/store'

export default {
  name: 'add-activity',
  props: {
    youthHome: [String, Number],
  },
  mixins: [BackendMixin, ActivitiesMixin],
  components: {
    ActivityEditor,
    LoadingGif,
    PageHeader,
  },
  data() {
    return {
      activity: makeActivity({ category: this.category, }),
      loadingName: 'activity',
    }
  },
  async created() {
  },
  watch: {
    activity: function(newValue, oldValue) {},
  },
  computed: {
    isYouthHome() {
      return +this.youthHome !== 0
    },
    activitiesTitle() {
      const menu = this.getActivityMenu()
      if (menu) {
        return menu.label
      } else {
        return 'Activités'
      }
    },
    activitiesIcon() {
      const menu = this.getActivityMenu()
      if (menu) {
        return menu.icon
      } else {
        return 'fas fa-chess-knight'
      }
    },
    canAdd() {
      if (this.youthHome) {
        return this.hasPerm('youth.add_youthhomeactivity')
      } else {
        return this.hasPerm('activities.add_coreactivity')
      }
    },
  },
  methods: {
    onActivityCreated(event) {
      router.push(this.getLinkToActivity(event.activity))
    },
    onCancel() {
      router.push(this.getLinkToActivitiesList())
    },
    getLinkToActivity(activity) {
      if (this.youthHome) {
        return { name: 'youth-homes-detail', params: { activityId: '' + activity.id, }, }
      } else {
        return { name: 'activities-detail', params: { activityId: '' + activity.id, }, }
      }
    },
    getLinkToActivitiesList() {
      if (this.youthHome) {
        return { name: 'youth-homes-list', }
      } else {
        return { name: 'activities-list', }
      }
    },
  },
}
</script>

<style lang="less">
</style>
